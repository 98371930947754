* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: Ubuntu, -apple-system, Arial, Helvetica, sans-serif;
}

html,
body,
#root {
  min-height: 100%;
  background-color: #222;
}

.container {
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 30pt;
  color: #4bb543;
}

h2 {
  color: #fff;
}

.error {
  text-align: justify;
  font-size: 15pt;
  font-weight: bold;
  color: #f55;
  margin-top: 10px;
}

@media (min-width: 700px) {
  .content {
    max-width: 1200px;
  }
}
