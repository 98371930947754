#dashboard-container {
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 80px;
}

#dashboard-content {
  justify-content: flex-start;
  align-items: center;
}

.phrase {
  text-align: center;
  font-size: 20pt;
  font-weight: bold;
  color: #fff;
}

.lyricsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
}

.reference {
  text-align: center;
  font-size: 14pt;
  color: #777;
}

.reference a {
  color: #fff;
}

.player-container {
  position: fixed;
  z-index: 100;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 97%;
  padding: 10px;

  background: #555;
  border-radius: 10px;
}

.buttons-container {
  position: relative;
  width: 100%;
  align-self: center;
  max-width: 400px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.buttons-container button {
  background: transparent;
  border: 0;
  color: #fff;
}

.buttons-container button:hover {
  cursor: pointer;
}

#slider {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 10px;
  width: 100%;
  background: #333;
  border-radius: 5px;
  margin-bottom: 15px;
}

#past-slider {
  position: relative;
  height: 10px;
  width: 15px;
  background: #4bb543;
  border-radius: 5px;

  box-shadow: inset 2px 2px 1px rgba(0,0,0,0.2);
}

#thumb {
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #fff;

  left: -2.5px;
  top: -2.5px;
}

#thumb:hover {
  cursor: pointer;
}

.alert {
  text-align: center;
  margin-top: 15px;
  color: #444;
}