#home-container {
  background: url("../../assets/music.svg") no-repeat 680px bottom;
}

.background-filter {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(34, 34, 34, 0.9);
}

.home-title {
  text-align: center;
}

.calling {
  text-align: justify;
  font-size: 16pt;
  font-weight: bold;
  color: #fff;
  margin-top: 30px;
}

.btn {
  height: 44px;
  display: flex;
  background-color: #4bb543;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 5px;
  align-self: center;
  margin-top: 70px;
  padding: 10px;

  font-size: 16px;
  font-weight: bold;
  color: #fff;

  cursor: pointer;
}

.btn:hover {
  background-color: #2f9428;
}
